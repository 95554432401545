import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import dashboard from './routes/dashboard'
import pages from './routes/pages'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
  routes: [
    ...dashboard,
    ...pages,
    {
      path: '/',
      redirect: { name: 'dashboard-analytics' },
    },
    {
      path: '/users',
      name: 'users',
      component: () => import('@/views/users/list.vue'),
    },
    {
      path: '/users/add',
      name: 'add-user',
      component: () => import('@/views/users/form.vue'),
      meta: {
        resource: 'users',
        pageTitle: 'Add User',
        breadcrumb: [
          {
            to: { name: 'users' },
            text: 'Users',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users/:resourceId',
      name: 'edit-user',
      component: () => import('@/views/users/form.vue'),
      meta: {
        resource: 'users',
        pageTitle: 'Edit User',
        breadcrumb: [
          {
            to: { name: 'users' },
            text: 'Users',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },

    {
      path: '/customers',
      name: 'customers',
      component: () => import('@/views/customers/list.vue'),
    },
    {
      path: '/customers/add',
      name: 'add-customer',
      component: () => import('@/views/customers/form.vue'),
      meta: {
        resource: 'customers',
        pageTitle: 'Add customer',
        breadcrumb: [
          {
            to: { name: 'customers' },
            text: 'Customers',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/customers/:resourceId',
      name: 'edit-customer',
      component: () => import('@/views/customers/form.vue'),
      meta: {
        resource: 'customers',
        pageTitle: 'Edit customer',
        breadcrumb: [
          {
            to: { name: 'customers' },
            text: 'Customers',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/contact-uses',
      name: 'contact-uses',
      component: () => import('@/views/contact-uses/list.vue'),
    },
    {
      path: '/contact-uses/:resourceId',
      name: 'contact-uses-details',
      component: () => import('@/views/contact-uses/show.vue'),
      meta: {
        resource: 'contact-uses',
        pageTitle: 'Show Message',
        breadcrumb: [
          {
            to: { name: 'contact-uses' },
            text: 'contact-uses',
          },
          {
            text: 'Show',
            active: true,
          },
        ],
      },
    },

    {
      path: '/roles',
      name: 'roles',
      component: () => import('@/views/roles/list.vue'),
    },
    {
      path: '/roles/add',
      name: 'add-role',
      component: () => import('@/views/roles/form.vue'),
      meta: {
        resource: 'roles',
        pageTitle: 'Add Role',
        breadcrumb: [
          {
            to: { name: 'roles' },
            text: 'Roles',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/roles/:resourceId',
      name: 'edit-role',
      component: () => import('@/views/roles/form.vue'),
      meta: {
        resource: 'roles',
        pageTitle: 'Edit Role',
        breadcrumb: [
          {
            to: { name: 'roles' },
            text: 'Roles',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },

    {
      path: '/update-data',
      name: 'update-data',
      component: () => import('@/views/update-data/list.vue'),
    },

    {
      path: '/countries',
      name: 'countries',
      component: () => import('@/views/countries/list.vue'),
    },
    {
      path: '/countries/add',
      name: 'add-country',
      component: () => import('@/views/countries/form.vue'),
      meta: {
        resource: 'countries',
        pageTitle: 'Add Country',
        breadcrumb: [
          {
            to: { name: 'countries' },
            text: 'Countries',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/countries/:resourceId',
      name: 'edit-country',
      component: () => import('@/views/countries/form.vue'),
      meta: {
        resource: 'countries',
        pageTitle: 'Edit Country',
        breadcrumb: [
          {
            to: { name: 'countries' },
            text: 'Countries',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/countries/:country_id/regions/',
      name: 'regions',
      meta: {
        resource: 'regions',
        pageTitle: 'Regions',
        breadcrumb: [
          {
            to: { name: 'countries' },
            text: 'Countries',
          },
          {
            text: 'Regions',
            active: true,
          },
        ],
      },
      component: () => import('@/views/countries/regions/list.vue'),
    },
    {
      path: '/countries/:country_id/regions/add',
      name: 'add-region',
      meta: {
        resource: 'regions',
        pageTitle: 'Add Regions',
        type: 'add',
        breadcrumb: [
          {
            to: { name: 'regions' },
            text: 'Regions',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
      component: () => import('@/views/countries/regions/form.vue'),
    },
    {
      path: '/countries/:country_id/regions/:resourceId/edit',
      name: 'edit-region',
      meta: {
        resource: 'regions',
        pageTitle: 'Edit Regions',
        type: 'edit',
        breadcrumb: [
          {
            to: { name: 'regions' },
            text: 'Regions',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
      component: () => import('@/views/countries/regions/form.vue'),
    },
    {
      path: '/countries/:country_id/cities/:region_id',
      name: 'cities',
      meta: {
        resource: 'cities',
        pageTitle: 'Cities',
        breadcrumb: [
          {
            to: { name: 'regions' },
            text: 'Regions',
          },
          {
            text: 'Cities',
            active: true,
          },
        ],
      },
      component: () => import('@/views/countries/cities/list.vue'),
    },
    {
      path: '/countries/:country_id/cities/:region_id/add',
      name: 'add-city',
      meta: {
        resource: 'cities',
        pageTitle: 'Add Cities',
        type: 'add',
        breadcrumb: [
          {
            to: { name: 'cities' },
            text: 'Cities',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
      component: () => import('@/views/countries/cities/form.vue'),
    },
    {
      path: '/countries/:country_id/cities/:region_id/:resourceId/edit',
      name: 'edit-city',
      meta: {
        resource: 'cities',
        pageTitle: 'Edit Cities',
        type: 'edit',
        breadcrumb: [
          {
            to: { name: 'cities' },
            text: 'Cities',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
      component: () => import('@/views/countries/cities/form.vue'),
    },
    {
      path: '/faster-notifications',
      name: 'faster-notifications',
      component: () => import('@/views/faster-notification/form.vue'),
    },
    {
      path: '/center-notifications',
      name: 'center-notifications',
      component: () => import('@/views/center-notification/form.vue'),
    },
    {
      path: '/customer-points-center',
      name: 'customer-points-center',
      component: () => import('@/views/customer-points-center/list.vue'),
    },
    {
      path: '/prizes-periods',
      name: 'prizes-periods',
      component: () => import('@/views/prizes-periods/list.vue'),
    },
    {
      path: '/prizes-period/add',
      name: 'add-prizes-period',
      component: () => import('@/views/prizes-periods/form.vue'),
      meta: {
        resource: 'prizes-periods',
        pageTitle: 'Add prizes period',
        breadcrumb: [
          {
            to: { name: 'prizes-periods' },
            text: 'Prizes period',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },

    {
      path: '/prizes-period/:resourceId',
      name: 'edit-prizes-period',
      component: () => import('@/views/prizes-periods/form.vue'),
      meta: {
        resource: 'prizes-periods',
        pageTitle: 'Edit prizes period',
        breadcrumb: [
          {
            to: { name: 'prizes-periods' },
            text: 'Prizes period',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/prizes',
      name: 'prizes',
      component: () => import('@/views/prizes/list.vue'),
    },
    {
      path: '/prize/add',
      name: 'add-prize',
      component: () => import('@/views/prizes/form.vue'),
      meta: {
        resource: 'prizes',
        pageTitle: 'Add prize',
        breadcrumb: [
          {
            to: { name: 'prizes' },
            text: 'Prizes',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/prize/:resourceId',
      name: 'edit-prize',
      component: () => import('@/views/prizes/form.vue'),
      meta: {
        resource: 'prizes',
        pageTitle: 'Edit Prizes',
        breadcrumb: [
          {
            to: { name: 'prizes' },
            text: 'Prizes',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },

    {
      path: '/news',
      name: 'news',
      component: () => import('@/views/news/list.vue'),
    },
    {
      path: '/news/add',
      name: 'add-news',
      component: () => import('@/views/news/form.vue'),
      meta: {
        resource: 'news',
        pageTitle: 'Add News',
        breadcrumb: [
          {
            to: { name: 'news' },
            text: 'News',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/news/:resourceId',
      name: 'edit-news',
      component: () => import('@/views/news/form.vue'),
      meta: {
        resource: 'news',
        pageTitle: 'Edit News',
        breadcrumb: [
          {
            to: { name: 'news' },
            text: 'News',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/news-categories',
      name: 'news-categories',
      component: () => import('@/views/news-categories/list.vue'),
    },
    {
      path: '/news-categories/add',
      name: 'add-news-category',
      component: () => import('@/views/news-categories/form.vue'),
      meta: {
        resource: 'news-categories',
        pageTitle: 'Add News Category',
        breadcrumb: [
          {
            to: { name: 'news-categories' },
            text: 'News Categories',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/news-categories/:resourceId',
      name: 'edit-news-category',
      component: () => import('@/views/news-categories/form.vue'),
      meta: {
        resource: 'news-categories',
        pageTitle: 'Edit News Category',
        breadcrumb: [
          {
            to: { name: 'news-categories' },
            text: 'News Categories',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/championships',
      name: 'championships',
      component: () => import('@/views/championships/list.vue'),
    },
    {
      path: '/championships/add',
      name: 'add-championship',
      component: () => import('@/views/championships/form.vue'),
      meta: {
        resource: 'championships',
        pageTitle: 'Add Championship',
        breadcrumb: [
          {
            to: { name: 'championships' },
            text: 'Championships',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/championships/:resourceId',
      name: 'edit-championship',
      component: () => import('@/views/championships/form.vue'),
      meta: {
        resource: 'championships',
        pageTitle: 'Edit championship',
        breadcrumb: [
          {
            to: { name: 'championships' },
            text: 'Championships',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    // {
    //   path: '/competitions',
    //   name: 'competitions',
    //   component: () => import('@/views/competitions/list.vue'),
    // },
    // {
    //   path: '/competitions/add',
    //   name: 'add-competition',
    //   component: () => import('@/views/competitions/form.vue'),
    //   meta: {
    //     resource: 'competitions',
    //     pageTitle: 'Add competition',
    //     breadcrumb: [
    //       {
    //         to: { name: 'competitions' },
    //         text: 'Competitions',
    //       },
    //       {
    //         text: 'Create',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    // {
    //   path: '/competitions/:resourceId',
    //   name: 'edit-competition',
    //   component: () => import('@/views/competitions/form.vue'),
    //   meta: {
    //     resource: 'competitions',
    //     pageTitle: 'Edit competitions',
    //     breadcrumb: [
    //       {
    //         to: { name: 'competitions' },
    //         text: 'Competitions',
    //       },
    //       {
    //         text: 'Edit',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    {
      path: '/leagues',
      name: 'leagues',
      component: () => import('@/views/leagues/list.vue'),
    },
    {
      path: '/leagues/add',
      name: 'add-league',
      component: () => import('@/views/leagues/form.vue'),
      meta: {
        resource: 'leagues',
        pageTitle: 'Add league',
        breadcrumb: [
          {
            to: { name: 'leagues' },
            text: 'Leagues',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/leagues/:resourceId',
      name: 'edit-league',
      component: () => import('@/views/leagues/form.vue'),
      meta: {
        resource: 'leagues',
        pageTitle: 'Edit league',
        breadcrumb: [
          {
            to: { name: 'leagues' },
            text: 'Leagues',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/tags',
      name: 'tags',
      component: () => import('@/views/tags/list.vue'),
    },
    {
      path: '/tags/add',
      name: 'add-tag',
      component: () => import('@/views/tags/form.vue'),
      meta: {
        resource: 'tags',
        pageTitle: 'Add tag',
        breadcrumb: [
          {
            to: { name: 'tags' },
            text: 'Tags',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/tags/:resourceId',
      name: 'edit-tag',
      component: () => import('@/views/tags/form.vue'),
      meta: {
        resource: 'tags',
        pageTitle: 'Edit tag',
        breadcrumb: [
          {
            to: { name: 'tags' },
            text: 'Tags',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },

    // {
    //   path: '/blogs',
    //   name: 'blogs',
    //   component: () => import('@/views/blogs/list.vue'),
    // },
    // {
    //   path: '/blogs/add',
    //   name: 'add-blog',
    //   component: () => import('@/views/blogs/form.vue'),
    //   meta: {
    //     resource: 'blogs',
    //     pageTitle: 'Add blog',
    //     breadcrumb: [
    //       {
    //         to: { name: 'blogs' },
    //         text: 'blogs',
    //       },
    //       {
    //         text: 'Create',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    // {
    //   path: '/blogs/:resourceId',
    //   name: 'edit-blog',
    //   component: () => import('@/views/blogs/form.vue'),
    //   meta: {
    //     resource: 'blogs',
    //     pageTitle: 'Edit blog',
    //     breadcrumb: [
    //       {
    //         to: { name: 'blogs' },
    //         text: 'blogs',
    //       },
    //       {
    //         text: 'Edit',
    //         active: true,
    //       },
    //     ],
    //   },
    // },

    {
      path: '/analysts',
      name: 'analysts',
      component: () => import('@/views/analysts/list.vue'),
    },
    {
      path: '/analysts/add',
      name: 'add-analyst',
      component: () => import('@/views/analysts/form.vue'),
      meta: {
        resource: 'analysts',
        pageTitle: 'Add analyst',
        breadcrumb: [
          {
            to: { name: 'analysts' },
            text: 'analysts',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/analysts/:resourceId',
      name: 'edit-analyst',
      component: () => import('@/views/analysts/form.vue'),
      meta: {
        resource: 'analysts',
        pageTitle: 'Edit analyst',
        breadcrumb: [
          {
            to: { name: 'analysts' },
            text: 'analysts',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/analytics',
      name: 'analytics',
      component: () => import('@/views/analytics/list.vue'),
    },
    {
      path: '/analytics/add',
      name: 'add-analytic',
      component: () => import('@/views/analytics/form.vue'),
      meta: {
        resource: 'analytics',
        pageTitle: 'Add analytic',
        breadcrumb: [
          {
            to: { name: 'analytics' },
            text: 'analytics',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/analytics/:resourceId',
      name: 'edit-analytic',
      component: () => import('@/views/analytics/form.vue'),
      meta: {
        resource: 'analytics',
        pageTitle: 'Edit analytic',
        breadcrumb: [
          {
            to: { name: 'analytics' },
            text: 'analytics',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/formations',
      name: 'formations',
      component: () => import('@/views/formations/list.vue'),
    },
    {
      path: '/formations/add',
      name: 'add-formation',
      component: () => import('@/views/formations/form.vue'),
      meta: {
        resource: 'formations',
        pageTitle: 'Add formation',
        breadcrumb: [
          {
            to: { name: 'formations' },
            text: 'formations',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/formations/:resourceId',
      name: 'edit-formation',
      component: () => import('@/views/formations/form.vue'),
      meta: {
        resource: 'formations',
        pageTitle: 'Edit formation',
        breadcrumb: [
          {
            to: { name: 'formations' },
            text: 'formations',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },

    {
      path: '/referees',
      name: 'referees',
      component: () => import('@/views/referees/list.vue'),
    },
    {
      path: '/referees/add',
      name: 'add-referee',
      component: () => import('@/views/referees/form.vue'),
      meta: {
        resource: 'referees',
        pageTitle: 'Add referee',
        breadcrumb: [
          {
            to: { name: 'referees' },
            text: 'referees',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/referees/:resourceId',
      name: 'edit-referee',
      component: () => import('@/views/referees/form.vue'),
      meta: {
        resource: 'referees',
        pageTitle: 'Edit referee',
        breadcrumb: [
          {
            to: { name: 'referees' },
            text: 'referees',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/banners',
      name: 'banners',
      component: () => import('@/views/banners/list.vue'),
    },
    {
      path: '/banners/add',
      name: 'add-banner',
      component: () => import('@/views/banners/form.vue'),
      meta: {
        resource: 'banners',
        pageTitle: 'Add banner',
        breadcrumb: [
          {
            to: { name: 'banners' },
            text: 'banners',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/banners/:resourceId',
      name: 'edit-banner',
      component: () => import('@/views/banners/form.vue'),
      meta: {
        resource: 'banners',
        pageTitle: 'Edit banner',
        breadcrumb: [
          {
            to: { name: 'banners' },
            text: 'banners',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },

    {
      path: '/reports',
      name: 'reports',
      component: () => import('@/views/reports/list.vue'),
    },
    {
      path: '/reports/:resourceId',
      name: 'report-details',
      component: () => import('@/views/reports/show.vue'),
      meta: {
        resource: 'reports',
        pageTitle: 'Show Report',
        breadcrumb: [
          {
            to: { name: 'reports' },
            text: 'reports',
          },
          {
            text: 'Show',
            active: true,
          },
        ],
      },
    },

    {
      path: '/scorers',
      name: 'scorers',
      component: () => import('@/views/scorers/list.vue'),
    },
    {
      path: '/scorers/add',
      name: 'add-scorer',
      component: () => import('@/views/scorers/form.vue'),
      meta: {
        resource: 'scorers',
        pageTitle: 'Add scorer',
        breadcrumb: [
          {
            to: { name: 'scorers' },
            text: 'scorers',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/scorers/:resourceId',
      name: 'edit-scorer',
      component: () => import('@/views/scorers/form.vue'),
      meta: {
        resource: 'scorers',
        pageTitle: 'Edit scorer',
        breadcrumb: [
          {
            to: { name: 'scorers' },
            text: 'scorers',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/pictures',
      name: 'pictures',
      component: () => import('@/views/pictures/list.vue'),
    },
    {
      path: '/pictures/add',
      name: 'add-picture',
      component: () => import('@/views/pictures/form.vue'),
      meta: {
        resource: 'pictures',
        pageTitle: 'Add picture',
        breadcrumb: [
          {
            to: { name: 'pictures' },
            text: 'Pictures',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/pictures/:resourceId',
      name: 'edit-picture',
      component: () => import('@/views/pictures/form.vue'),
      meta: {
        resource: 'pictures',
        pageTitle: 'Edit picture',
        breadcrumb: [
          {
            to: { name: 'pictures' },
            text: 'Pictures',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },

    {
      path: '/short-videos',
      name: 'short-videos',
      component: () => import('@/views/short-videos/list.vue'),
    },
    {
      path: '/short-videos/add',
      name: 'add-short-video',
      component: () => import('@/views/short-videos/form.vue'),
      meta: {
        resource: 'short-videos',
        pageTitle: 'Add short video',
        breadcrumb: [
          {
            to: { name: 'short-videos' },
            text: 'Short Videos',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/short-videos/:resourceId',
      name: 'edit-short-video',
      component: () => import('@/views/short-videos/form.vue'),
      meta: {
        resource: 'short-videos',
        pageTitle: 'Edit short video',
        breadcrumb: [
          {
            to: { name: 'short-videos' },
            text: 'Short Videos',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/players',
      name: 'players',
      component: () => import('@/views/players/list.vue'),
    },
    {
      path: '/players/add',
      name: 'add-player',
      component: () => import('@/views/players/form.vue'),
      meta: {
        resource: 'players',
        pageTitle: 'Add player',
        breadcrumb: [
          {
            to: { name: 'players' },
            text: 'Players',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/players/:resourceId',
      name: 'edit-player',
      component: () => import('@/views/players/form.vue'),
      meta: {
        resource: 'players',
        pageTitle: 'Edit player',
        breadcrumb: [
          {
            to: { name: 'players' },
            text: 'Players',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/matches',
      name: 'matches',
      component: () => import('@/views/matches/list.vue'),
    },
    {
      path: '/matches/add',
      name: 'add-match',
      component: () => import('@/views/matches/form.vue'),
      meta: {
        resource: 'matches',
        pageTitle: 'Add match',
        breadcrumb: [
          {
            to: { name: 'matches' },
            text: 'Matches',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/matches/:resourceId',
      name: 'edit-match',
      component: () => import('@/views/matches/form.vue'),
      meta: {
        resource: 'matches',
        pageTitle: 'Edit match',
        breadcrumb: [
          {
            to: { name: 'matches' },
            text: 'Matches',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/stadiums',
      name: 'stadiums',
      component: () => import('@/views/stadiums/list.vue'),
    },
    {
      path: '/stadiums/add',
      name: 'add-stadium',
      component: () => import('@/views/stadiums/form.vue'),
      meta: {
        resource: 'stadiums',
        pageTitle: 'Add stadium',
        breadcrumb: [
          {
            to: { name: 'stadiums' },
            text: 'Stadiums',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/stadiums/:resourceId',
      name: 'edit-stadium',
      component: () => import('@/views/stadiums/form.vue'),
      meta: {
        resource: 'stadiums',
        pageTitle: 'Edit stadium',
        breadcrumb: [
          {
            to: { name: 'stadiums' },
            text: 'Stadiums',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/teams',
      name: 'teams',
      component: () => import('@/views/teams/list.vue'),
    },
    {
      path: '/teams/add',
      name: 'add-team',
      component: () => import('@/views/teams/form.vue'),
      meta: {
        resource: 'teams',
        pageTitle: 'Add team',
        breadcrumb: [
          {
            to: { name: 'teams' },
            text: 'Teams',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/teams/:resourceId',
      name: 'edit-team',
      component: () => import('@/views/teams/form.vue'),
      meta: {
        resource: 'teams',
        pageTitle: 'Edit team',
        breadcrumb: [
          {
            to: { name: 'teams' },
            text: 'Teams',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import('@/views/settings/list.vue'),
    },
    {
      path: '/settings/:resourceId',
      name: 'edit-setting',
      component: () => import('@/views/settings/form.vue'),
      meta: {
        resource: 'settings',
        pageTitle: 'Edit setting',
        breadcrumb: [
          {
            to: { name: 'settings' },
            text: 'Settings',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },

    {
      path: '/splash-screen-settings',
      name: 'splash-screen-settings',
      component: () => import('@/views/splash-screen-setting/list.vue'),
    },
    {
      path: '/splash-screen-setting/add',
      name: 'add-splash-screen-setting',
      component: () => import('@/views/splash-screen-setting/form.vue'),
      meta: {
        resource: 'splash-screen-settings',
        pageTitle: 'Add Splash Screen Setting',
        breadcrumb: [
          {
            to: { name: 'splash-screen-setting' },
            text: 'Splash Screen Settings',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },

    {
      path: '/splash-screen-setting/:resourceId',
      name: 'edit-splash-screen-setting',
      component: () => import('@/views/splash-screen-setting/form.vue'),
      meta: {
        resource: 'splash-screen-settings',
        pageTitle: 'Edit splash screen setting',
        breadcrumb: [
          {
            to: { name: 'splash-screen-setting' },
            text: 'Splash Screen Settings',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/writers',
      name: 'writers',
      component: () => import('@/views/writers/list.vue'),
    },
    {
      path: '/writers/add',
      name: 'add-writer',
      component: () => import('@/views/writers/form.vue'),
      meta: {
        resource: 'writers',
        pageTitle: 'Add writer',
        breadcrumb: [
          {
            to: { name: 'writers' },
            text: 'Writers',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/writers/:resourceId',
      name: 'edit-writer',
      component: () => import('@/views/writers/form.vue'),
      meta: {
        resource: 'writers',
        pageTitle: 'Edit Writer',
        breadcrumb: [
          {
            to: { name: 'writers' },
            text: 'Writers',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/youtube-channels',
      name: 'youtube-channels',
      component: () => import('@/views/youtube-channels/list.vue'),
    },
    {
      path: '/youtube-channels/add',
      name: 'add-youtube-channel',
      component: () => import('@/views/youtube-channels/form.vue'),
      meta: {
        resource: 'youtube-channels',
        pageTitle: 'Add youtube channel',
        breadcrumb: [
          {
            to: { name: 'youtube-channels' },
            text: 'youtube channels',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/youtube-channels/:resourceId',
      name: 'edit-youtube-channel',
      component: () => import('@/views/youtube-channels/form.vue'),
      meta: {
        resource: 'youtube-channels',
        pageTitle: 'Edit youtube channel',
        breadcrumb: [
          {
            to: { name: 'youtube-channels' },
            text: 'youtube channels',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/usage-terms',
      name: 'usage-terms',
      component: () => import('@/views/usage-term/list.vue'),
    },
    {
      path: '/usage-term/add',
      name: 'add-usage-term',
      component: () => import('@/views/usage-term/form.vue'),
      meta: {
        resource: 'usage-terms',
        pageTitle: 'Add usage term',
        breadcrumb: [
          {
            to: { name: 'usage-terms' },
            text: 'Usage terms',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },



    {
      path: '/usage-term/:resourceId',
      name: 'edit-usage-term',
      component: () => import('@/views/usage-term/form.vue'),
      meta: {
        resource: 'usage-terms',
        pageTitle: 'Edit usage term',
        breadcrumb: [
          {
            to: { name: 'usage-terms' },
            text: 'usage terms',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
